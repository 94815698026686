import Main from "../components/cse";
import Footer from "../components/cse/Footer";
import Header from "../components/cse/Header";

export default function Cse() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
