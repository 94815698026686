import React from "react";
import Header from "../../components/EbsBarTh145/Header";
import Main from "../../components/EbsBarTh145";
import Footer from "../../components/EbsBarTh145/Footer";
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

const BarTh145 = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <Header secondTitle={''} />

     
      <>
    
        <div className="flex flex-col justify-center h-full gap-5 p-5">
          <Button
            style={{
              backgroundColor: "rgb(67, 60, 191)",
            }}
            onClick={() => handleClick("/bar-th-145/ebs")}
            variant="contained"
            disableElevation
          >
            EBS
          </Button>

          <Button
            style={{
              backgroundColor: "rgb(72 122 160)",
            }}
            onClick={() => handleClick("/bar-th-145/total")}
            variant="contained"
            disableElevation
          >
            Total
          </Button>
        </div>
        <Footer />
      </>
    </>
  );
};

export default BarTh145;
