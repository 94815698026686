import Main from "../components/ebs";
import Footer from "../components/ebs/Footer";
import Header from "../components/ebs/Header";

export default function Ebs() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
