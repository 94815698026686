import Main from "../components/ebs-v2";
import Footer from "../components/ebs-v2/Footer";
import Header from "../components/ebs-v2/Header";

export default function EbsV2() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
