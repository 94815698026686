import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomTable({
  result,
  inputValue,
  setInputValue,
  handleSubmitCefProject,
  isolationData,
  scenario,
}) {
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <div style={{ minWidth: "1000px", maxWidth: "1100px" }}>
      <table className="min-w-full">
        <thead class="bg-gray-50">
          <tr>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              Prime
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              Cef Initiale
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              Cef Projet
            </th>

            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              Shab
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              B
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              CUMAC
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.amount1}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.cef_initial}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.cef_projet1}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>

            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.b1}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.cumac1}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>SC1: </strong> Scénario preferentiel : PAC / Ballon / 101
              ET/OU 103
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              className={`text-center py-3 px-4 border  text-sm text-gray-500`}
            >
              <CurrencyFormat
                value={result?.amount2}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td
              className={`text-center py-3 px-4 border  text-sm text-gray-500`}
            >
              <CurrencyFormat
                value={result?.cef_initial2}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td
              className={`text-center py-3 px-4 border  text-sm text-gray-500`}
            >
              <CurrencyFormat
                value={result?.cef_projet2}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>

            <td
              className={`text-center py-3 px-4 border text-sm text-gray-500`}
            >
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.b2}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.cumac2}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>SC2: </strong> Scénario BBC = PAC + BALLON + 102
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <div className="flex-col justify-around ml-6 mr-6">
        <div className="flex justify-around mb-1">
          <span>après le 1 Aout</span>
        </div>
        <table className="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th
                style={{ backgroundColor: "#1b5fa8", color: "white" }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                CUMAC
              </th>

              <th
                style={{ backgroundColor: "#1b5fa8", color: "white" }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                Prime
              </th>
              <th
                style={{ backgroundColor: "#1b5fa8", color: "white" }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                RAC
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.aout?.cumac}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>

              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.aout?.prime}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.aout?.rac}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              {/* <td colSpan="7" className="px-4 py-3 text-sm text-center text-gray-500 border">
          <strong>SC: </strong>101+103+ PAC +BT / 101+PAC +BT/ 102 +
          PAC+BT
        </td> */}
              <td
                colSpan="7"
                className="w-full px-4 py-3 text-sm text-center text-red-600 whitespace-normal border"
              >
                Selon l'arrêté du 27 juin 2023 modifiant l'arrêté du 22 décembre
                2014 définissant les opérations standardisées d'économies
                d'énergie et l'arrêté du 29 décembre 2014 relatif aux modalités
                d'application du dispositif des certificats d'économies
                d'énergie, les primes CEE sont écrêté a 3.85 GWh.cumac par
                logement et 0.0231 GWh.cumac/m² .
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
      <div className="flex justify-around">
        <table className="">
          <thead class="bg-gray-50">
            <tr>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                TRAVAUX
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                M²
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                COUT TRAVAUX
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>ITE</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.ITE + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.ITE}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>RAMPANTS</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.RAMPANTS + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.RAMPANTS}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>COMBLES</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.COMBLES + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.COMBLES}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>PLANCHER BAS</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.PLANCHERBAS + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.PLANCHERBAS}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table className="">
          <thead class="bg-gray-50">
            <tr>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                TRAVAUX
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                QT
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                COUT TRAVAUX
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong> PAC AIR/EAU OU AIR/AIR</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                1
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.pacEauAir}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong> CHAUFFE-EAU THERMODYNAMIQUE</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                1
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.chaufEauTHermodynamique}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong> POELE A GRANNULÉ</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {scenario?.poeleAGrannule}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.PoeleAGrannule}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
      <table className="min-w-full">
        <thead class="bg-gray-50">
          <tr>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              SCENARIOS
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              COUT TRAVAUX
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              DIFFERENCE
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              class="px-6 py-2 text-xs text-gray-500"
            >
              RAC
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>ITE + PAC + BALLON</strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.itePacBaloon.cout}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.itePacBaloon.diff}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.itePacBaloon.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>RAMPANTS + PAC + BALLON</strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.ramponPacBaloonPoele.cout}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.ramponPacBaloonPoele.diff}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.ramponPacBaloonPoele.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>COMBLES + PAC + BALLON</strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.comblesPacBaloonPoele.cout}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.comblesPacBaloonPoele.diff}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.comblesPacBaloonPoele.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          {/* <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>PLANCHER BAS + PAC + BALLON</strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.PLANCHERBASPacBaloonPoele.cout}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.PLANCHERBASPacBaloonPoele.diff}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.PLANCHERBASPacBaloonPoele.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr> */}
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>
                {isolationData?.ITE !== 0 && "ITE "}
                {isolationData?.ITE !== 0 &&
                  isolationData?.COMBLES !== 0 &&
                  "+"}
                {isolationData?.COMBLES !== 0 && " COMBLES "}
                {isolationData?.RAMPANTS !== 0 &&
                  isolationData?.COMBLES !== 0 &&
                  "+"}
                {isolationData?.RAMPANTS !== 0 && " RAMPANTS "}
                {isolationData?.PLANCHERBAS !== 0 && "+ PLANCHER BAS "}
                {result?.pacEauAir !== 0 && "+ PAC "}
                {result?.chaufEauTHermodynamique !== 0 && "+ BALLON "}
                {result?.PoeleAGrannule !== 0 && "+ POELE "}
              </strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.TOTAL?.cout}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.TOTAL?.diff}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.TOTAL?.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
