import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "https://betechniques.fr/api/api",

  // baseURL: "http://localhost:8001/api",
  baseURL: "https://test-back-v2.betechniques.fr/api",

  withCredentials: true,
  headers: {
    // Accept: ["application/json", "application/pdf"],
  },
});

export default axiosClient;
