export default function Header() {
  return (
    <header>
      <nav
        style={{ backgroundColor: "#1b5fa8" }}
        className="bg-gray-300 flex flex-col   justify-center h-28 text-center  border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800"
      >
        <div className="self-center text-xl font-bold text-white whitespace-nowrap ">
          Simulateur Interne BAR TH 164
        </div>
        <div className="self-center text-xl font-bold text-white whitespace-nowrap ">
          VERTIGO
        </div>
      </nav>
    </header>
  );
}
