import { Button } from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";
import Footer from "../components/ebs/Footer";
import Header from "../components/Header";

export default function Home() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <Header title={"Simulateur"}   />
      <div className="flex flex-col justify-center h-full gap-5 p-5">
        <Button
          style={{
            backgroundColor: "rgb(15, 40, 130)",
          }}
          onClick={() => handleClick("/bar-th-164")}
          variant="contained"
          disableElevation
        >
          Simulation BAR TH 164
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(72 122 160)",
          }}
          onClick={() => handleClick("/bar-th-145")}
          variant="contained"
          disableElevation
        >
          Simulation BAR TH 145
        </Button>
      </div>
      <Footer />
    </>
  );
}
