import "./styled/animation.css";
import CategoryForm from "./CustomForm/CategoryForm";
import CustomForm from "./CustomForm/CustomForm";

export function StepsFunction({
  index,
  handleSelectedData,
  selectedOptionDropMain,
  setSelectedOptionDropMain,
  selectedOptionDropD,
  setSelectedOptionDropD,
  customFormData,
  setcustomFormData,
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
  valorisation,
  setValorisation,
  isolationData,
  setIsolationData,
  scenario,
  setScenario,
  nbLogements,
  setNbLogements,
  partCommun,
  setPartCommun,
  shabPrime,
  setShabPrime,
  sommeShab,
  setSommeShab,
  logements,
  setLogements,
}) {
  switch (index) {
    case 0:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <CustomForm
            selectedOptionDropMain={selectedOptionDropMain}
            setSelectedOptionDropMain={setSelectedOptionDropMain}
            selectedOptionDropD={selectedOptionDropD}
            setSelectedOptionDropD={setSelectedOptionDropD}
            customFormData={customFormData}
            setcustomFormData={setcustomFormData}
            selectedOptionIChaufageCom={selectedOptionIChaufageCom}
            setSelectedOptionIChaufageCom={setSelectedOptionIChaufageCom}
            valorisation={valorisation}
            setValorisation={setValorisation}
            //
            logements={logements}
            setLogements={setLogements}
            nbLogements={nbLogements}
            setNbLogements={setNbLogements}
            partCommun={partCommun}
            setPartCommun={setPartCommun}
            shabPrime={shabPrime}
            setShabPrime={setShabPrime}
            sommeShab={sommeShab}
            setSommeShab={setSommeShab}
          />
        </div>
      );
    case 1:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <CategoryForm
            scenario={scenario}
            setScenario={setScenario}
            isolationData={isolationData}
            setIsolationData={setIsolationData}
          />
        </div>
      );
  }
}
