import React from "react";
import Header from "../../components/EbsBarTh145/Header";
import Main from "../../components/EbsBarTh145";
import Footer from "../../components/EbsBarTh145/Footer";
const BarTh145Ebs = () => {
  return (
    <>
      <Header secondTitle={"Ebs"} />

      <Main type={"ebs-bar-th-145"} />
      <Footer />
    </>
  );
};

export default BarTh145Ebs;
