import React, { useEffect, useState } from "react";

export default function CategoryForm({
  setScenario,
  scenario,
  setIsolationData,
  isolationData,
}) {
  const handleChange = (e) => {
    setIsolationData({
      ...isolationData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeScenario = (e) => {
    setScenario({
      ...scenario,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form className="max-w-md mx-auto">
      {/* <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 md:w md:mb-0">
          <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
            ITE
          </label>
          <input
            style={{ borderColor: "#1b5fa8" }}
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="ITE"
            name="ITE"
            type="text"
            placeholder="m²"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div> */}
      {/* <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 md:w md:mb-0">
          <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
            ITI
          </label>
          <input
            style={{ borderColor: "#1b5fa8" }}
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="ITI"
            name="ITI"
            type="text"
            placeholder="m²"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div> */}
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 md:w md:mb-0">
          <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
            RAMPANTS
          </label>
          <input
            style={{ borderColor: "#1b5fa8" }}
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="RAMPANTS"
            name="RAMPANTS"
            type="text"
            placeholder="m²"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 md:w md:mb-0">
          <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
            COMBLES
          </label>
          <input
            style={{ borderColor: "#1b5fa8" }}
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="COMBLES"
            name="COMBLES"
            type="text"
            placeholder="m²"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 md:w md:mb-0">
          <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
            Plancher Bas
          </label>
          <input
            style={{ borderColor: "#1b5fa8" }}
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="PLANCHERBAS"
            name="PLANCHERBAS"
            type="text"
            placeholder="m²"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 md:w md:mb-0">
          <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
            Poêle a Grannulé
          </label>
          <input
            value={scenario?.poeleAGrannule}
            style={{ borderColor: "#1b5fa8" }}
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="poeleAGrannule"
            name="poeleAGrannule"
            type="text"
            placeholder="Poêle"
            onChange={(e) => handleChangeScenario(e)}
          />
        </div>
      </div>
    </form>
  );
}
