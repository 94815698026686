import React from "react";
import {
  useChaufage,
  useIsolationComble,
  useIsolationMur,
  useIsolationPlanchar,
  useMitoyenne,
  useTypefenetre,
} from "../../../api/calculeApi";
import Dropdown from "./Dropdown";
import SearchDropdown from "./SearchDropdown";

export default function ListChaufForm({
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
  selectedOptionIsolationMur,
  setSelectedOptionIsolationMur,
  selectedOptionIsolationPlan,
  setSelectedOptionIsolationPlan,
  selectedOptionIsolationCom,
  setSelectedOptionIsolationCom,
}) {
  const chaufage = useChaufage("vertigo");
  const isolationMur = useIsolationMur();
  const isolationPlanchar = useIsolationPlanchar();
  const isolationComble = useIsolationComble();
  const chaufageData = chaufage?.data;
  const isolationMurData = isolationMur?.data;
  const isolationPlancharData = isolationPlanchar?.data;
  const isolationCombleData = isolationComble?.data;
  // const [selectedOptionMiton, setSelectedOptionMiton] = React.useState({});
  // const [selectedOptionMitonTypeFen, setSelectedOptionMitonTypeFen] =
  return (
    <div className="w-full max-w-md mx-auto">
      <form className="px-8 pt-6 pb-2 mb-4">
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Système de chauffage :
            </label>
            {chaufage.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionIChaufageCom}
                setSelectedOptionDropMain={setSelectedOptionIChaufageCom}
                precariteData={chaufageData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Isolation des Murs :
            </label>
            {isolationMur.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionIsolationMur}
                setSelectedOptionDropMain={setSelectedOptionIsolationMur}
                precariteData={isolationMurData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Isolation des planchers bas :
            </label>
            {isolationPlanchar.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionIsolationPlan}
                setSelectedOptionDropMain={setSelectedOptionIsolationPlan}
                precariteData={isolationPlancharData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Isolation des combles :
            </label>
            {isolationComble.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionIsolationCom}
                setSelectedOptionDropMain={setSelectedOptionIsolationCom}
                precariteData={isolationCombleData}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
