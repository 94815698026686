import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomTable({
  result,
  inputValue,
  setInputValue,
  handleSubmitCefProject,
  isolationData,
  scenario,
}) {
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <div style={{ minWidth: "1000px", maxWidth: "1100px" }}>
      <div className="flex justify-around mb-1">
        <strong className="text-lg font-bold text-red-500">
          Avant le 1 Aout
        </strong>
      </div>
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              Gain CEF
            </th>

            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              Coeff B
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.gainBefore}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>

            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                value={result?.CoefB}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>SC1: </strong>
              {/* 101+103
               */}
              {isolationData?.COMBLES !== 0 || isolationData?.RAMPANTS !== 0
                ? "101"
                : ""}
              {isolationData?.PLANCHERBAS !== 0 ? "+103" : ""}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="flex mt-5">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                N°
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                Shab
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                Cumac
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                Prime
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {result?.BeforeAugest?.perLogement.map((logement, index) => (
              <tr key={index} className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>logement ({index + 1})</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {logement?.shab}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={logement?.CUMAC}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>

                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={logement?.Prime}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
              </tr>
            ))}
            <tr className="whitespace-nowrap">
              <td
                colSpan="6"
                className="px-4 py-3 text-sm text-center text-gray-500 border"
              >
                <strong>Prime Total: </strong>
                <CurrencyFormat
                  value={result?.BeforeAugest?.PrimeTotal}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td
                colSpan="6"
                className="px-4 py-3 text-sm text-center text-gray-500 border"
              >
                <strong>Cumac Total: </strong>
                <CurrencyFormat
                  value={result?.BeforeAugest?.CumacTotal}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>

      <br></br>
      <div className="flex justify-around">
        <table className="">
          <thead className="bg-gray-50">
            <tr>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                TRAVAUX
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                M²
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                COUT TRAVAUX
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>RAMPANTS</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.RAMPANTS + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.Rampants}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>COMBLES</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.COMBLES + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.COMBLES}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>PLANCHER BAS</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {isolationData?.PLANCHERBAS + " m²"}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.PLANCHERBAS}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table className="">
          <thead className="bg-gray-50">
            <tr>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                TRAVAUX
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                QT
              </th>
              <th
                style={{
                  backgroundColor: "#1b5fa8",
                  color: "white",
                }}
                className="px-6 py-2 text-xs text-gray-500"
              >
                COUT TRAVAUX
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong> PAC AIR/EAU OU AIR/AIR</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                1
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.PAC}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong> CHAUFFE-EAU THERMODYNAMIQUE</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                1
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.chaufEauTHermodynamique}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong> POELE A GRANNULÉ</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {scenario?.poeleAGrannule}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.PoeleAGrannule}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              SCENARIOS
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              COUT TRAVAUX
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              DIFFERENCE
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              RAC
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>
                {/* RAMPANTS + PAC + BALLON */}
                {result?.scenario?.RampantsPacBallon?.name}
              </strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.RampantsPacBallon?.works_cost}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.RampantsPacBallon?.differnce}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.RampantsPacBallon?.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>
                {/* COMBLES + PAC + BALLON
                 */}
                {result?.scenario?.ComblesPacBallon?.name}
              </strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.ComblesPacBallon?.works_cost}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.ComblesPacBallon?.differnce}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.ComblesPacBallon?.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>
                {/* PLANCHER BAS + PAC + BALLON */}
                {result?.scenario?.PlancherBasPacBallon?.name}
              </strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.PlancherBasPacBallon?.works_cost}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.PlancherBasPacBallon?.differnce}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.PlancherBasPacBallon?.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>
                {/* {isolationData?.COMBLES !== 0 && "COMBLE + "}
                PLANCHER BAS + PAC + BALLON */}
                {result?.scenario?.ComblePlancherBasPacBallon?.name}
              </strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.ComblePlancherBasPacBallon?.works_cost}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.ComblePlancherBasPacBallon?.differnce}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={result?.scenario?.ComblePlancherBasPacBallon?.rac}
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <strong>
                {/* {isolationData?.COMBLES !== 0 && " COMBLES "}
                {((isolationData?.RAMPANTS !== 0 &&
                  isolationData?.COMBLES !== 0) ||
                  (isolationData?.ITE !== 0 &&
                    isolationData?.RAMPANTS !== 0)) &&
                  "+"}
                {isolationData?.RAMPANTS !== 0 && " RAMPANTS "}
                {isolationData?.PLANCHERBAS !== 0 && "+ PLANCHER BAS "}
                {result?.pacEauAir !== 0 && "+ PAC "}
                {result?.chaufEauTHermodynamique !== 0 && "+ BALLON "}
                {result?.PoeleAGrannule !== 0 && "+ POELE "} */}
                {
                  result?.scenario?.ComblesRampantsPlancherBasPacBallonPoele
                    ?.name
                }
              </strong>
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={
                  result?.scenario?.ComblesRampantsPlancherBasPacBallonPoele
                    ?.works_cost
                }
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              <CurrencyFormat
                prefix={"€"}
                value={
                  result?.scenario?.ComblesRampantsPlancherBasPacBallonPoele
                    ?.differnce
                }
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-500 border">
              {/* <CurrencyFormat */}
              <CurrencyFormat
                prefix={"€"}
                value={
                  result?.scenario?.ComblesRampantsPlancherBasPacBallonPoele
                    ?.rac
                }
                thousandSeparator={true}
                displayType={"text"}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <div className="flex-col justify-around ml-6 mr-6">
        <div className="flex justify-around mb-1">
          <strong className="text-lg font-bold text-red-500">
            Après le 1 Aout
          </strong>
        </div>
      </div>
      {/* <table className="w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              N°
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              Shab
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              Cumac
            </th>
            <th
              style={{
                backgroundColor: "#1b5fa8",
                color: "white",
              }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              Prime
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {result?.AfterAugust?.perLogement.map((logement, index) => (
            <tr key={index} className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <strong>logement ({index + 1})</strong>
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                {logement?.shab}
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={logement?.CUMAC}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>

              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={logement?.Prime}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
          ))}

          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>Prime Total: </strong>
              <CurrencyFormat
                value={result?.AfterAugust?.PrimeTotal}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>Cumac Total: </strong>
              <CurrencyFormat
                value={result?.AfterAugust?.CumacTotal}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
        </tbody>
      </table> */}
      <br></br>
      <table className="w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              style={{ backgroundColor: "#1b5fa8", color: "white" }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              N°
            </th>
            <th
              style={{ backgroundColor: "#1b5fa8", color: "white" }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              CUMAC
            </th>

            <th
              style={{ backgroundColor: "#1b5fa8", color: "white" }}
              className="px-6 py-2 text-xs text-gray-500"
            >
              Prime
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {result?.AfterRegulationAugust?.perLogement.map((log, key) => (
            <tr className="whitespace-nowrap">
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                Logement ({key + 1})
              </td>
              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={log?.CUMAC}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </td>

              <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={log?.Prime}
                  thousandSeparator={true}
                  prefix={"€"}
                  displayType={"text"}
                />
              </td>
            </tr>
          ))}
          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>Prime Total: </strong>
              <CurrencyFormat
                value={result?.AfterRegulationAugust?.PrimeTotal}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>Cumac Total: </strong>
              <CurrencyFormat
                value={result?.AfterRegulationAugust?.CumacTotal}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          <tr className="whitespace-nowrap">
            <td
              colSpan="2"
              className="px-6 py-4 text-sm text-center text-gray-500 border"
            >
              <strong>Scénario: </strong>
              {result?.lastScenario?.name}
            </td>
            <td
              colSpan="1"
              className="px-6 py-4 text-sm text-center text-gray-500 border"
            >
              <strong>RAC: </strong>
              <CurrencyFormat
                value={result?.lastScenario?.rac}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr>
          {/* <tr className="whitespace-nowrap">
            <td
              colSpan="6"
              className="px-4 py-3 text-sm text-center text-gray-500 border"
            >
              <strong>RAC: </strong>
              <CurrencyFormat
                value={result?.lastScenario?.rac}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </td>
          </tr> */}
          <tr className="whitespace-nowrap">
            <td
              colSpan="7"
              className="w-full px-4 py-3 text-sm text-center text-red-600 whitespace-normal border"
            >
              Selon l'arrêté du 27 juin 2023 modifiant l'arrêté du 22 décembre
              2014 définissant les opérations standardisées d'économies
              d'énergie et l'arrêté du 29 décembre 2014 relatif aux modalités
              d'application du dispositif des certificats d'économies d'énergie,
              les primes CEE sont écrêté a 3.85 GWh.cumac par logement et
              0.0231 GWh.cumac/m² .
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
