import * as React from "react";

import SearchDropdown from "./SearchDropdown";
import Dropdown from "./Dropdown.jsx";
import {
  useChaufage145,
  useCodePostal,
  usePrecarite,
} from "../../../api/calculeApi";

export default function CustomForm({
  selectedOptionDropMain,
  setSelectedOptionDropMain,
  selectedOptionDropD,
  setSelectedOptionDropD,
  customFormData,
  setcustomFormData,
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
  handleSelectedData,
  valorisation,
  setValorisation,

  //
  logements,
  setLogements,
  nbLogements,
  setNbLogements,
  partCommun,
  setPartCommun,
  shabPrime,
  setShabPrime,
  sommeShab,
  setSommeShab,
}) {
  const options = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
    "Option 5",
    "Option 6",
    "Option 7",
    "Option 8",
  ];

  const [completed, setCompleted] = React.useState(0);

  const handleChange = (e) => {
    setcustomFormData({
      ...customFormData,
      [e.target.name]: e.target.value,
    });
  };
  const chaufage = useChaufage145("ebs");

  const codePostal = useCodePostal();
  const precarite = usePrecarite();
  const codePostalData = codePostal?.data;
  const precariteData = precarite?.data;
  const chaufageData = chaufage?.data;

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("etape_1", JSON.stringify(customFormData));
    localStorage.setItem("code_postal", JSON.stringify(selectedOptionDropD));
    localStorage.setItem("precarite", JSON.stringify(selectedOptionDropMain));
    setCompleted(1);
  };

  const handleNbLogementShab = (value) => {
    const diff = value - nbLogements;

    setNbLogements(value);

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        const newLogement = { shab: 0 };
        setLogements((prevLogements) => [...prevLogements, newLogement]);
      }
    } else if (diff < 0) {
      setLogements((prevLogements) => prevLogements.slice(0, value));
    }
  };

  const handleChangeShab = (value, index) => {
    const updatedLogements = logements.map((logement, i) => {
      if (i === index) {
        return { ...logement, shab: parseInt(value, 10) };
      }
      return logement;
    });
    const totalShab = updatedLogements.reduce(
      (total, logement) => total + logement.shab,
      0
    );

    setLogements(updatedLogements);

    setSommeShab(totalShab);
    setShabPrime(totalShab - partCommun);
  };
  const handleChangePartCommun = (value) => {
    setPartCommun(value);
    setShabPrime(sommeShab - value);
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <form className="px-8 pt-1 pb-2 mb-4" onSubmit={handleSubmit}>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w md:mb-0">
            <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
              Nom et Prénom
            </label>
            <input
              style={{ borderColor: "#1b5fa8" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="nom"
              name="nom"
              type="text"
              placeholder="Entrez votre nom et pernom"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Nombre de Logement
            </label>
            <input
              style={{ borderColor: "#1b5fa8" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="logement"
              name="logement"
              min={1}
              type="number"
              value={nbLogements}
              placeholder="Nb Logement"
              onChange={(e) => handleNbLogementShab(e.target.value)}
            />
          </div>
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="CodePostal"
            >
              Code Postal
            </label>
            {codePostal.isSuccess && (
              <SearchDropdown
                selectedOptionDropD={selectedOptionDropD}
                setSelectedOptionDropD={setSelectedOptionDropD}
                options={options}
                codePostalData={codePostalData}
              />
            )}
          </div>
        </div>
        {logements?.map((log, key) => (
          <div className="flex flex-wrap mb-6 -mx-3">
            <div className="w-full px-3 mb-6 md:w md:mb-0">
              <label
                className="block mb-2 font-bold text-gray-700"
                htmlFor="Surface"
              >
                Surface habitable (Logement {key + 1})
              </label>
              <input
                style={{ borderColor: "#1b5fa8" }}
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="surface"
                name="surface"
                min={0}
                value={log.shab}
                type="number"
                placeholder="Entrez surface"
                onChange={(e) => handleChangeShab(e.target.value, key)}
              />
            </div>
          </div>
        ))}

        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Partie commune (Non eligible )
            </label>
            <input
              style={{ borderColor: "#1b5fa8" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="surface"
              name="surface"
              min={0}
              type="number"
              placeholder="Entrez surface"
              value={partCommun}
              onChange={(e) => handleChangePartCommun(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Shab totale
            </label>
            <input
              style={{ borderColor: "#1b5fa8" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="surface"
              name="surface"
              value={sommeShab}
              type="text"
              placeholder="Shab totale"
              // onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="CodePostal"
            >
              Shab eligible à la prime
            </label>
            <input
              style={{ borderColor: "#1b5fa8" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="surface"
              name="surface"
              min={0}
              type="number"
              value={shabPrime}
            />
          </div>
        </div>

        {/* <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="precarite"
            >
              Précarité
            </label>
            {precarite.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionDropMain}
                setSelectedOptionDropMain={setSelectedOptionDropMain}
                options={options}
                precariteData={precariteData}
              />
            )}
          </div>
        </div> */}
        <div className="mb-4">
          <label className="block mb-2 font-bold text-gray-700">
            Système de chauffage :
          </label>
          <div>
            {chaufage.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionIChaufageCom}
                setSelectedOptionDropMain={setSelectedOptionIChaufageCom}
                precariteData={chaufageData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="precarite"
            >
              Valorisation
            </label>
            <input
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="precarite"
              type="number"
              value={valorisation}
              onChange={(e) => setValorisation(e.target.value)}
              placeholder="Valorisation ..."
              min={0}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
