import React from "react";
import Header from "../../components/EbsBarTh145/Header";
import Main from "../../components/EbsBarTh145";
import Footer from "../../components/EbsBarTh145/Footer";

const BarTh145Total = () => {
  return (
    <>
      <Header secondTitle={"Total"} />

      <Main type={"total-bar-th-145"} />
      <Footer />
    </>
  );
};

export default BarTh145Total;
