import axiosClient from "./axiosClient";
import { useMutation, useQuery } from "react-query";

const calculate = async ({ payloadData, type }) => {
  const parsed = await axiosClient.post(
    `calculate-intern/${type}`,
    payloadData
  );
  return parsed.data;
};

const calculateBar = async (values) => {
  const parsed = await axiosClient.post("calcul-bar-th", values);
  return parsed.data;
};

const useCalculate = () => {
  return useMutation(["calculate"], ({ payloadData, type }) =>
    calculate({ payloadData, type })
  );
};
const useCalculateBar = (values) => {
  return useMutation(["calculate-bar"], (values) => calculateBar(values));
};

const getCodePostal = async () => {
  const parsed = await axiosClient.get("code-postal");
  return parsed.data;
};

const downloadPdf = async (file_name) => {
  const parsed = await axiosClient.get(`download_pdf/${file_name}`);
  return parsed.data;
};
const useDownloadPdf = (file_name) => {
  return useMutation(["pdf"], () => downloadPdf(file_name));
};
const useCodePostal = () => {
  return useQuery(["code-postal"], () => getCodePostal());
};

const getPrecarite = async () => {
  const parsed = await axiosClient.get("precaire");
  return parsed.data;
};

const usePrecarite = () => {
  return useQuery(["precaire"], () => getPrecarite());
};

const getMitoyenne = async () => {
  const parsed = await axiosClient.get("mitoyenne");
  return parsed.data;
};

const useMitoyenne = () => {
  return useQuery(["mitoyenne"], () => getMitoyenne());
};

const getTypefenetre = async () => {
  const parsed = await axiosClient.get("type-fenetre");
  return parsed.data;
};

const useTypefenetre = () => {
  return useQuery(["type-fenetre"], () => getTypefenetre());
};

const getIsolationMur = async () => {
  const parsed = await axiosClient.get("isolation-mur");
  return parsed.data;
};

const useIsolationMur = () => {
  return useQuery(["isolation-mur"], () => getIsolationMur());
};

const getIsolationPlanchar = async () => {
  const parsed = await axiosClient.get("isolation-planchar");
  return parsed.data;
};

const useIsolationPlanchar = () => {
  return useQuery(["isolation-planchar"], () => getIsolationPlanchar());
};

const getIsolationComble = async () => {
  const parsed = await axiosClient.get("isolation-comble");
  return parsed.data;
};

const useIsolationComble = () => {
  return useQuery(["isolation-comble"], () => getIsolationComble());
};

const getChaufage = async (type) => {
  const parsed = await axiosClient.get(`chaufage/${type}`);
  return parsed.data;
};

const useChaufage = (type) => {
  return useQuery(["chaufage", type], () => getChaufage(type));
};

const getChaufage145 = async (type) => {
  const parsed = await axiosClient.get(`chaufage/145/${type}`);
  return parsed.data;
};
const useChaufage145 = (type) => {
  return useQuery(["chaufage", type], () => getChaufage145(type));
};

export {
  useChaufage145,
  useChaufage,
  useCalculate,
  useCodePostal,
  usePrecarite,
  useMitoyenne,
  useTypefenetre,
  useIsolationMur,
  useIsolationPlanchar,
  useIsolationComble,
  useDownloadPdf,
  useCalculateBar,
};
